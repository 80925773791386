// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-advance-faq-advance-faq-js": () => import("./../../../src/components/AdvanceFaq/AdvanceFaq.js" /* webpackChunkName: "component---src-components-advance-faq-advance-faq-js" */),
  "component---src-components-forgot-password-sg-forgot-password-sg-js": () => import("./../../../src/components/ForgotPasswordSg/ForgotPasswordSg.js" /* webpackChunkName: "component---src-components-forgot-password-sg-forgot-password-sg-js" */),
  "component---src-components-how-to-apps-components-how-to-id-js": () => import("./../../../src/components/HowToAppsComponents/HowToId.js" /* webpackChunkName: "component---src-components-how-to-apps-components-how-to-id-js" */),
  "component---src-components-how-to-apps-components-how-to-sg-js": () => import("./../../../src/components/HowToAppsComponents/HowToSg.js" /* webpackChunkName: "component---src-components-how-to-apps-components-how-to-sg-js" */),
  "component---src-components-send-money-send-money-js": () => import("./../../../src/components/SendMoney/SendMoney.js" /* webpackChunkName: "component---src-components-send-money-send-money-js" */),
  "component---src-components-sg-referral-singapore-referral-js": () => import("./../../../src/components/SgReferral/SingaporeReferral.js" /* webpackChunkName: "component---src-components-sg-referral-singapore-referral-js" */),
  "component---src-components-sg-referral-tnc-sg-referral-tnc-js": () => import("./../../../src/components/SgReferralTnc/SgReferralTnc.js" /* webpackChunkName: "component---src-components-sg-referral-tnc-sg-referral-tnc-js" */),
  "component---src-components-singapore-faq-singapore-faq-js": () => import("./../../../src/components/SingaporeFaq/SingaporeFaq.js" /* webpackChunkName: "component---src-components-singapore-faq-singapore-faq-js" */),
  "component---src-components-singapore-privacy-policy-sg-privacy-policy-js": () => import("./../../../src/components/SingaporePrivacyPolicy/SgPrivacyPolicy.js" /* webpackChunkName: "component---src-components-singapore-privacy-policy-sg-privacy-policy-js" */),
  "component---src-components-singapore-service-agreement-sg-service-agreement-js": () => import("./../../../src/components/SingaporeServiceAgreement/SgServiceAgreement.js" /* webpackChunkName: "component---src-components-singapore-service-agreement-sg-service-agreement-js" */),
  "component---src-components-update-confirmation-parent-js": () => import("./../../../src/components/UpdateConfirmation/parent.js" /* webpackChunkName: "component---src-components-update-confirmation-parent-js" */),
  "component---src-page-components-about-us-js": () => import("./../../../src/pageComponents/about-us.js" /* webpackChunkName: "component---src-page-components-about-us-js" */),
  "component---src-page-components-affiliate-js": () => import("./../../../src/pageComponents/affiliate.js" /* webpackChunkName: "component---src-page-components-affiliate-js" */),
  "component---src-page-components-business-js": () => import("./../../../src/pageComponents/business.js" /* webpackChunkName: "component---src-page-components-business-js" */),
  "component---src-page-components-card-js": () => import("./../../../src/pageComponents/card.js" /* webpackChunkName: "component---src-page-components-card-js" */),
  "component---src-page-components-cashinstore-js": () => import("./../../../src/pageComponents/cashinstore.js" /* webpackChunkName: "component---src-page-components-cashinstore-js" */),
  "component---src-page-components-contact-us-js": () => import("./../../../src/pageComponents/contact-us.js" /* webpackChunkName: "component---src-page-components-contact-us-js" */),
  "component---src-page-components-cookie-policy-js": () => import("./../../../src/pageComponents/cookie-policy.js" /* webpackChunkName: "component---src-page-components-cookie-policy-js" */),
  "component---src-page-components-download-js": () => import("./../../../src/pageComponents/download.js" /* webpackChunkName: "component---src-page-components-download-js" */),
  "component---src-page-components-ewallet-tnc-js": () => import("./../../../src/pageComponents/ewallet-tnc.js" /* webpackChunkName: "component---src-page-components-ewallet-tnc-js" */),
  "component---src-page-components-privacy-policy-js": () => import("./../../../src/pageComponents/privacy-policy.js" /* webpackChunkName: "component---src-page-components-privacy-policy-js" */),
  "component---src-page-components-service-agreement-js": () => import("./../../../src/pageComponents/service-agreement.js" /* webpackChunkName: "component---src-page-components-service-agreement-js" */),
  "component---src-page-components-sg-js": () => import("./../../../src/pageComponents/sg.js" /* webpackChunkName: "component---src-page-components-sg-js" */),
  "component---src-page-components-syarat-dan-ketentuan-referral-program-js": () => import("./../../../src/pageComponents/syarat-dan-ketentuan-referral-program.js" /* webpackChunkName: "component---src-page-components-syarat-dan-ketentuan-referral-program-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affiliate-form-js": () => import("./../../../src/pages/affiliate-form.js" /* webpackChunkName: "component---src-pages-affiliate-form-js" */),
  "component---src-pages-affiliate-submitted-js": () => import("./../../../src/pages/affiliate-submitted.js" /* webpackChunkName: "component---src-pages-affiliate-submitted-js" */),
  "component---src-pages-bic-swift-code-checker-js": () => import("./../../../src/pages/bic-swift-code-checker.js" /* webpackChunkName: "component---src-pages-bic-swift-code-checker-js" */),
  "component---src-pages-countries-js": () => import("./../../../src/pages/countries.js" /* webpackChunkName: "component---src-pages-countries-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-swift-codes-js": () => import("./../../../src/pages/swift-codes.js" /* webpackChunkName: "component---src-pages-swift-codes-js" */),
  "component---src-pages-transfezsingapura-js": () => import("./../../../src/pages/transfezsingapura.js" /* webpackChunkName: "component---src-pages-transfezsingapura-js" */),
  "component---src-pages-transfezsingapura-tnc-js": () => import("./../../../src/pages/transfezsingapura-tnc.js" /* webpackChunkName: "component---src-pages-transfezsingapura-tnc-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

